
import { Api } from '@vicons/carbon'
import { defineComponent } from 'vue'
import { useDialog } from 'naive-ui'
import { IAPIReturnWithReview } from '../../interfaces/hospital'
import * as api from '../utils/api'
export default defineComponent({
	name: 'Hospital',
	components: {
		Api,
	},
	props: ['apply', 'from'],
	setup() {
		return {
			
		}
	},
	data() {
		return {
			liked: false,
			id: '',
			rateDetails: -1,
			rateCeil: -1,
			comment: '',
			count: 0,
		}
	},
	mounted: function () {
		const apply = this.apply as IAPIReturnWithReview
		this.id = apply.id
		const reviews = apply.reviews
		const count = reviews.length
		let target = 0
		let targetCt = 0
		for (const r of reviews) {
			if (r.rating1 > 0) target = target + r.rating1
			if (r.rating1 > 0) targetCt = targetCt + 1
			if (r.rating2 > 0) target = target + r.rating2
			if (r.rating2 > 0) targetCt = targetCt + 1
			if (r.rating3 > 0) target = target + r.rating3
			if (r.rating3 > 0) targetCt = targetCt + 1
			if (r.rating4 > 0) target = target + r.rating4
			if (r.rating4 > 0) targetCt = targetCt + 1
			if (r.rating5 > 0) target = target + r.rating5
			if (r.rating5 > 0) targetCt = targetCt + 1
			if (r.rating6 > 0) target = target + r.rating6
			if (r.rating6 > 0) targetCt = targetCt + 1
		}
		const rateDetails = Math.round((target / targetCt) * 10) / 10
		const rateCeil = Math.ceil(rateDetails)
		this.count = count
		this.rateDetails = rateDetails
		this.rateCeil = rateCeil
		const nowFavedStr = localStorage.getItem('faved')
		const nowFaved = nowFavedStr?.split(',') || []
		const isFavedStorage = nowFaved.includes(apply.id)
		this.liked = isFavedStorage
	},
	methods: {
		like: async function (id: string) {
			const t = document.querySelector(`#h__${this.apply.id}`)
			this.liked = !this.liked
			if (this.liked) {
				const nowFavedStr = localStorage.getItem('faved')
				const nowFaved = nowFavedStr?.split(',') || []
				nowFaved.push(id)
				localStorage.setItem('faved', nowFaved.join(','))
			} else if (!this.liked) {
				const nowFavedStr = localStorage.getItem('faved')
				const nowFaved = nowFavedStr?.split(',') || []
				const newFaved = nowFaved.filter((i) => i !== id)
				localStorage.setItem('faved', newFaved.join(','))
			}
		},
		goToReview: function (id: string) {
			location.href = `/apply/${id}/#review`
		},
		getJobStyle: (type: 'fulltime' | 'parttime' | 'temporary') => {
			return { fulltime: '正社員', parttime: 'アルバイト', temporary: '業務委託' }[type]
		},
		getJobType: (type: 'veterinarian' | 'nurse' | 'trimmer' | 'trainer') => {
			return { veterinarian: '獣医', nurse: '看護師', trimmer: 'トリマー', trainer: 'トレーナー' }[type]
		},
		getSalary: function (obj: any) {
			if (!obj) return ''
			const s: string[] = []
			if (obj.hourly) s.push(`時給${this.makeSplit(obj.hourly.min)}円${!obj.hourly.definitive ? '〜' : ''}${obj.hourly.max ? `${this.makeSplit(obj.hourly.max)}円` : ''}`)
			if (obj.daily) s.push(`日給${this.makeSplit(obj.daily.min)}円${!obj.daily.definitive ? '〜' : ''}${obj.daily.max ? `${this.makeSplit(obj.daily.max)}円` : ''}`)
			if (obj.monthly) s.push(`月収${this.makeSplit(obj.monthly.min)}円${!obj.monthly.definitive ? '〜' : ''}${obj.monthly.max ? `${this.makeSplit(obj.monthly.max)}円` : ''}`)
			if (obj.annual) s.push(`年収${this.makeSplit(obj.annual.min)}円${!obj.annual.definitive ? '〜' : ''}${obj.annual.max ? `${this.makeSplit(obj.annual.max)}円` : ''}`)
			let t = s.join('/')
			if (obj.note) t = `${t}(備考: ${obj.note})`
			return t
		},
		makeSplit: function (yen: number) {
			return yen.toLocaleString()
		},
	},
})
