
import BudouX from '../components/BudouX.vue'
import { ref, defineComponent } from 'vue'
import * as api from '../utils/api'
import Hospital from '../components/Hospital.vue'
import Review from '../components/Review.vue'
import Header from '../components/menu/Public.vue'
import { BookmarkAdd, NoImage } from '@vicons/carbon'
import { useDialog } from 'naive-ui'
import { IAPIReturnWithReview, IAPIReturnWithReviewInterview } from '../../interfaces/hospital'
import { IReview } from '../../interfaces/review'

export default defineComponent({
	components: {
		Hospital,
		Header,
		BudouX,
		Review,
		BookmarkAdd,
		NoImage,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		const warningDialog = (onPositiveClick: Function) => {
			const d = dialog.success({
				title: '応募しますか？',
				content: '続行を押した後、病院に対してメッセージを送ることでやり取りがスタートします。',
				positiveText: '続行',
				onPositiveClick: async () => {
					d.loading = true
					await onPositiveClick()
					d.loading = false
				},
			})
		}
		return {
			errorDialog,
			warningDialog,
			id: '',
			hospitalId: '',
			admin: api.isAdmin(),
			here: location.href,
			apply: {} as IAPIReturnWithReviewInterview,
			reviews: [] as IReview[],
		}
	},
	mounted: function () {
		this.init()
		if (location.hash === '#review') {
			const interval = setInterval(function () {
				const element = document.getElementById('review')
				if (element) clearInterval(interval)
				const rect = element?.getBoundingClientRect()
				if (!rect) return
				window.scroll({
					top: rect.top,
					behavior: 'smooth',
				})
			}, 100)
		}
		if (location.hash === '#apply') {
			const interval = setInterval(function () {
				const element = document.getElementById('apply')
				if (element) clearInterval(interval)
				const rect = element?.getBoundingClientRect()
				if (!rect) return
				window.scroll({
					top: rect.top,
					behavior: 'smooth',
				})
			}, 100)
		}
	},
	methods: {
		init: async function () {
			try {
				const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
				if (!fileNames) return false
				const fileName = fileNames[1]
				this.id = fileName
				const data = (await api.get(`/v1/apply/${fileName}`, true)) as IAPIReturnWithReviewInterview
				this.apply = data
				this.hospitalId = data.hospitalData.id
				this.reviews = data.reviews
				document.title = `${data.hospitalData.name} - Vets Index`
				const head = document.getElementsByTagName('head')[0]
				const meta = document.createElement('meta')
				meta.setAttribute('name', 'description')
				meta.setAttribute('content', data.descriptionSanitized)
				head.appendChild(meta)
			} catch (e: any) {
				this.errorDialog(e, () => history.back())
			}
		},
		getJobStyle: (type: 'fulltime' | 'parttime' | 'temporary') => {
			return { fulltime: '正社員', parttime: 'アルバイト', temporary: '業務委託' }[type]
		},
		getJobType: (type: 'veterinarian' | 'nurse' | 'trimmer' | 'trainer') => {
			return { veterinarian: '獣医', nurse: '看護師', trimmer: 'トリマー', trainer: 'トレーナー' }[type]
		},
		getSalary: function (obj: any) {
			if (!obj) return ''
			const s: string[] = []
			if (obj.hourly) s.push(`時給${obj.hourly.min}円${!obj.hourly.definitive ? '〜' : ''}${obj.hourly.max ? `${obj.hourly.max}円` : ''}`)
			if (obj.daily) s.push(`日給${obj.daily.min}円${!obj.daily.definitive ? '〜' : ''}${obj.daily.max ? `${obj.daily.max}円` : ''}`)
			if (obj.monthly) s.push(`月収${obj.monthly.min}円${!obj.monthly.definitive ? '〜' : ''}${obj.monthly.max ? `${obj.monthly.max}円` : ''}`)
			if (obj.annual) s.push(`年収${obj.annual.min}円${!obj.annual.definitive ? '〜' : ''}${obj.annual.max ? `${obj.annual.max}円` : ''}`)
			let t = s.join('/')
			return t
		},
		viewMore: function () {
			this.$router.push(`/h/${this.hospitalId}#review`)
		},
	},
})
